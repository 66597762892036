<template>
    <div>
        <GenericCrud
            route-name="SettingsRecordFilter"
            route-action="list"
            :navbar-title="$t('settings.filter.buttonLabel')"
            :navbar-back-route-without-history="{ name: 'Settings' }"
            :main-list-filter-label="$t('settings.filter.filterLabel')"
            main-fist-scroll-to-selected
            :storeData="{
                mainListItems: {
                    action: 'filter/fetch',
                    payload: { filterType: $route.params.filterType, force: true },
                    options: null,
                    itemText: 'title'
                },
                mainItemById: {
                    action: 'filter/fetchById',
                    payload: null,
                    options: null,
                },
                edit: {
                    action: 'filter/edit',
                    payload: { filterType: $route.params.filterType },
                    options: null,
                    refetch: true,
                    hideButton: true
                },
                add: {
                    action: 'filter/create',
                    payload: { filterType: $route.params.filterType },
                    options: null
                },
                delete: {
                    action: 'filter/delete',
                    payload: { filterType: $route.params.filterType },
                    options: null,
                    hideButton: true
                }
            }"
            :newItemModelFields="{
                forcedTranslation: true,
                clientId: null,
                title: null,
                description: null,
                icon: null,
                companies: [],
                departmentCategories: [],
                domains: [],
                groupsOfPersons: [],
                legality: [],
                tools: [],
                status: [],
                priorities: [],
                dpiaRequirements: [],
                dpiaCompleted: [],
                companyInternalKeywords: [],
                contactItemEditors: [],
                contactItemOwners: [],
                contactItemCoordinators: [],
                resubmission: [],
                appropriateSafeguardsExt: [],
                appropriateSafeguardsCorp: [],
                processDocumentation: [],
                personalDataCategories: [],
                processingTypeExt: [],
                processingTypeCorp: [],
                lcmExt: [],
                lcmCorp: [],
                authorityReportRelevance: [],
                infoObligationsFulfilled: [],
                controller: [],
                processor: [],
                processorCompanies: [],
                processorVendorCompanies: [],
                locale: currentLanguage,
                translations: defaultTranslations

            }"
        >
            <template v-slot:editorMask="x">
                <EditorMask
                    v-model="x.selectedMainItem"
                    :value="x.selectedMainItem"
                    :key="x.id"
                    :useVendor="getCompanyGroupSettings('useVendor')"
                    :filterOptions="filterOptions"
                    :useAuthorityReportRelevance="useAuthorityReportRelevance"
                    :available-languages="availableLanguages"
                    :current-language="currentLanguage"
                    :isWaiting="x.isWaiting"
                    :isModified="x.isModified"
                    @modified="x.onSetModified"
                    @onSaveItem="x.onSaveItem"
                    @onDeleteItem="x.onDeleteItem"
                    @onCreateItem="x.onCreateItem"
                />
            </template>
        </GenericCrud>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GenericCrud from '@/components/generic/GenericCrud.vue';
import EditorMask from './EditorMask.vue';

export default {
    name: 'SettingspersonalDataCategoriesIndex',
    components: {
        GenericCrud,
        EditorMask,
    },
    i18n: {
        messages: {
            en: require('@/locales/Settings/en.json'),
            de: require('@/locales/Settings/de.json'),
        },
    },
    computed: {
        ...mapGetters({
            availableLanguages: 'languageSettings/getAvailableLanguages',
            currentLanguage: 'languageSettings/getCurrentLanguage',
            filterOptions: 'filter/getFilterOptions',
            getCompanyGroupSettings: 'companyGroupSettings/get',
        }),
        useAuthorityReportRelevance () {
            return this.getCompanyGroupSettings('useAuthorityReportRelevance');
        },
        defaultTranslations() {
            let translations = this.availableLanguages.map(x => {
                return { title: '', description: '', locale: x};
            });
            
            return translations.sort((a, b) => {
                return (b.locale === this.currentLanguage && a.locale !== this.currentLanguage) ? 1 : -1;
            });
        }
    },
    methods: {
        ...mapActions({
            fetchOptions: 'filter/fetchOptions',
            fetchSettings: 'companyGroupSettings/fetch',
        }),
        fetchData() {
            this.$wait.start('pageLoader');
            this.fetchSettings();
            this.fetchOptions().then(() => {
                this.$wait.end('pageLoader');
            })
        }
    },
    mounted() {
        this.fetchData();
    },
};
</script>
