<template>
    <v-card :disabled="disabled" flat>
        <v-card-subtitle class="px-0 caption" v-text="label" />
        <v-card-text class="px-0">
            <v-autocomplete
                :items="items"
                v-model="model"
                :label="noFilterMsg"
                item-value="id"
                :item-text="itemText"
                :multiple="multiple"
                solo
                hide-details
                full-width
                clearable
                :disabled="disabled"
                :return-object="returnObject"
                @input="$emit('modified', $event)"
            >
            </v-autocomplete>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'FilterInput',
    props: {
        value: {
            type: [Array, Number],
            default: null
        },
        items: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            default: null
        },
        itemText: {
            type: String,
            default: 'title'
        },
        noFilterMsg: {
            type: String,
            default: null
        },
        multiple: {
            type: Boolean,
            default: true
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        }
    }
};
</script>
